import {
  ADD_ITEM,
  REMOVE_ITEM,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  UPDATE_METADATA,
  UPDATE_SHIPPING,
  UPDATE_PINCODE_LIST,
  RESET_CART,
  UPDATE_ADDONS,
  MATCH_ADDONS,
  UPDATE_ADDRESS,
  UPDATE_RECIEVER_DATA,
  UPDATE_PROMOTION,
  UPDATE_LOYALTY,
  UPDATE_DELIVERY_TYPE,
  UPDATE_DELIVERY_DATE,
  UPDATE_TRANSACTION_ID,
  UPDATE_REFFER,
} from "../actionTypes";

const initialState = {
  productTotal: 0,
  cartShipping: 0,
  shippingBreakDown: [],
  cartTotal: 0,
  address: {},
  mobileNumber: "",
  promoCodeApplied: "",
  promoAmount: 0,
  loyaltyPoints: 0,
  products: [],
  deliveryType: "in-lucknow",
  deliveryDate: null,
  txnid: "",
  recieverName: "",
  refferName: "",
  customAmount: 0,
  addons: [],
  pincodeList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_PINCODE_LIST: {
      const { pincodes } = action.payload;
      return {
        ...state,
        pincodeList: pincodes,
      };
    }
    case ADD_ITEM: {
      const { product } = action.payload;
      return {
        ...state,
        products: [...state.products, product],
      };
    }
    case REMOVE_ITEM: {
      const { index } = action.payload;
      let locCart = { ...state };
      locCart.products.splice(index, 1);
      return {
        ...state,
        products: locCart.products,
      };
    }
    case UPDATE_METADATA: {
      let productTotal = 0;
      let locCart = { ...state };
      locCart.products.forEach((product) => {
        productTotal =
          productTotal +
          Math.ceil(
            (100 - product.product.productDiscount) *
              0.01 *
              product.selectedVariant.productPrice *
              product.quantity
          );
      });
      return {
        ...state,
        productTotal: productTotal,
      };
    }
    case UPDATE_SHIPPING: {
      const { shipping, shipBD } = action.payload;
      let cartTotal = 0;
      let locState = { ...state };
      cartTotal = locState.productTotal + shipping;
      return {
        ...state,
        cartShipping: shipping,
        cartTotal: cartTotal,
        shippingBreakDown: shipBD,
      };
    }
    case INCREASE_QUANTITY: {
      const { index } = action.payload;
      let locCart = { ...state };
      locCart.products[index].quantity++;
      return {
        ...state,
        products: locCart.products,
      };
    }
    case DECREASE_QUANTITY: {
      const { index } = action.payload;
      let locCart = { ...state };
      locCart.products[index].quantity--;
      return {
        ...state,
        products: locCart.products,
      };
    }
    case RESET_CART: {
      return {
        ...state,
        productTotal: 0,
        cartShipping: 0,
        cartTotal: 0,
        products: [],
      };
    }
    case UPDATE_ADDONS: {
      const { addons } = action.payload;
      return {
        ...state,
        addons: addons,
      };
    }
    case MATCH_ADDONS: {
      let locState = { ...state };
      let cart = locState.products;
      let _addons = locState.addons;
      _addons.map(
        (item, index) =>
          (item.added = cart.find((cartItem) => {
            return (
              cartItem.product.productDisplayURL === item.productDisplayURL
            );
          })
            ? true
            : false)
      );
      return {
        ...state,
        addons: _addons,
      };
    }
    case UPDATE_ADDRESS: {
      const { address } = action.payload;
      return {
        ...state,
        address: address,
      };
    }
    case UPDATE_REFFER: {
      const { reffer, amount } = action.payload;
      return {
        ...state,
        refferName: reffer,
        customAmount: amount,
      };
    }
    case UPDATE_RECIEVER_DATA: {
      const { mobNo, name } = action.payload;
      return {
        ...state,
        mobileNumber: mobNo,
        recieverName: name,
      };
    }
    case UPDATE_PROMOTION: {
      const { promoCode, promoAmount } = action.payload;
      return {
        ...state,
        promoCodeApplied: promoCode,
        promoAmount: promoAmount,
      };
    }
    case UPDATE_LOYALTY: {
      const { points, promoAmount } = action.payload;
      return {
        ...state,
        loyaltyPoints: points,
        promoAmount: promoAmount,
      };
    }
    case UPDATE_DELIVERY_TYPE: {
      const { delType } = action.payload;
      return {
        ...state,
        deliveryType: delType,
      };
    }
    case UPDATE_DELIVERY_DATE: {
      const { delDate } = action.payload;
      return {
        ...state,
        deliveryDate: delDate,
      };
    }
    case UPDATE_TRANSACTION_ID: {
      const { transId } = action.payload;
      return {
        ...state,
        txnid: transId,
      };
    }
    default:
      return state;
  }
}

const ApiSuffix = {
  analytics: "/api/analytics/",
  login: "/api/login/",
  logout: "/api/logout/",
  register: "/api/register/",
  genOtp: "/api/generate-otp/",
  verifyOtp: "/api/verify-otp/",
  searchProduct: "/api/search-products/?search=",
  getUserProfile: "/api/get-user-profile/",
  updateUserProfile: "/api/update-user-profile/",
  getLoyaltyData: "/api/get-loyalty-table/",
  // getUserLoyaltyInfo: "/api/user-loyalty-info/",
  redeemLoyaltyPoints: "/api/redeem-loyalty-points/",
  sendResetPasswordMail: "/api/send-reset-password-mail/",
  verifyResetPassword: "/api/verify-reset-password-token/",
  resetPassword: "/api/reset-password/",
  // getCategories: "/api/get-categories/",
  getCategoryDetails: "/api/category-details/",
  productDetails: "/api/product-details/",
  getFeaturedCategories: "/api/get-featured-categories/",
  getFeaturedProducts: "/api/get-featured-products/",
  getPolicyData: "/api/get-policy-data/",
  addCartItem: "/api/add-item-cart/",
  getCartItems: "/api/get-cart-items/",
  getAddons: "/api/get-add-ons/",
  getShipingCharges: "/api/get-shipping-charges/",
  updateCartItem: "/api/update-item-cart/",
  deleteCartItem: "/api/delete-item-cart/",
  mergeCartItems: "/api/merge-cart-items/",
  getCurrentTime: "/api/check-delivery-time/",
  getAllAddress: "/api/get-all-address/",
  createAddress: "/api/create-address/",
  updateAddress: "/api/update-address/",
  deleteAddress: "/api/delete-address/",
  verifyAddress: "/api/verify-address/",
  applyCoupon: "/api/applyCoupon/",
  orderVerification: "/api/order-verification/",
  orderConfirmation: "/api/order-confirmation/",
  orderRegistration: "/api/order-registration/",
  easebuzzOrderRegistration: "/api/easebuzz-order-registeration/",
  customOrderRegistration: "/api/custom-order-registration/",
  getBranchesData: "/api/branches/",
  getProfileOrder: "/api/get-user-orders/",
  getOrderDetail: "/api/order-details/",
  getSiteData: "/api/get-site-data/",
  getFooterContent: "/api/get-footer-content",
  verifyPincode: "/api/verify-pincode/",
  validateInLKOPincode: "/api/validate-pincode/",
  getAllBlogs: "/api/list-blogs/",
  getBlogData: "/api/get-blog/",
  getThickContent: "/api/get-thick-content/",
  getAllCategories: "/api/new-new-categories/",
  getStores: "/api/get-store/",
  getStoreMenu: "/api/store-category-details/",
  validateCart: "/api/validate-cart-items/",
  getPincodesList: "/api/get-all-pincodes/",
  calculateShipping: "/api/calculate-shipping-charges/",
  getStoresPincode: "/api/store-pincode/",
};

export default ApiSuffix;

import React from "react";

import Button from "@material-ui/core/Button";
import { TextField, CircularProgress } from "@material-ui/core";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import * as Images from "../../Assets/Images";
import ImageLoader from "../ImageLazyLoader/ImageLoader";
import BaseUrl from "../BaseUrl";
import ApiSuffix from "../ApiSuffix";

import "./Header.css";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      searching: false,
      searchResults: {},
      searched: false,
    };
    this.apiSuffix = ApiSuffix;
    this.baseUrl = BaseUrl;
  }

  handleSearchTermChange = (e) => {
    this.setState({ searchTerm: e.target.value });
  };

  triggerSearch = (e) => {
    e.preventDefault();
    if (this.state.searchTerm.length > 2) {
      this.searchProduct();
    }
  };

  searchProduct = () => {
    this.setState({ searching: true });
    const url =
      this.baseUrl.dev + this.apiSuffix.searchProduct + this.state.searchTerm;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          searchResults: data,
          searching: false,
          searched: true,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleDialogClose = () => {
    this.props.closeDialog();
  };

  goToCatalogue = () => {
    this.props.closeDialog();
    this.props.history.push("/catalogue");
  };

  openProduct = (item) => {
    let url = "/shop/" + item.categoryDisplayURL + "/" + item.productDisplayURL;
    this.props.closeDialog();
    this.props.history.push(url);
  };

  render = () => {
    return (
      <React.Fragment>
        <div className="search-header">
          <div className="search-heading">Product Search</div>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.handleDialogClose}
          >
            <div className="font-monst-bold">Close</div>
          </Button>
        </div>
        <div>
          <div className="search-text-feild">
            <form onSubmit={this.triggerSearch}>
              <TextField
                id="serch-term"
                type="text"
                // label="Product Name to search"
                style={{ width: "300px" }}
                className="text-field-margin"
                placeholder="Product Name to Search"
                // margin="dense"
                variant="outlined"
                value={this.state.searchTerm}
                onChange={this.handleSearchTermChange}
              />
            </form>
            {this.state.searching ? (
              <div>
                <CircularProgress />
              </div>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={this.triggerSearch}
              >
                <SearchRoundedIcon />
                <div className="font-monst-bold">Search</div>
              </Button>
            )}
          </div>
          {this.state.searchResults.length > 0 ? (
            <div>
              <TransitionGroup className="cart-list">
                {this.state.searchResults.map((item, index) => (
                  <CSSTransition
                    key={index}
                    timeout={400}
                    classNames="item"
                    appear
                  >
                    <div className="search-list-item">
                      <div className="search-list-image">
                        <ImageLoader
                          src={item.productImageURL}
                          minUrl={item.productImageURL50}
                          alt={item.productName}
                        />
                      </div>
                      <div className="search-item-details">
                        <div className="search-item-name">
                          {item.productName}
                        </div>
                        <div className="search-cat-tags">
                          {item.categoryName}
                        </div>
                        <div className="search-product-description">
                          {item.productDescription.substring(0, 150) + "..."}
                        </div>
                        <div style={{ margin: "10px 0px" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => this.openProduct(item)}
                          >
                            <div className="font-monst-bold">View Product</div>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </div>
          ) : (
            <div className="item-not-found">
              <img
                src={Images.ITEM_NOT_FOUND}
                className="item-not-found-image"
                alt="Category not found"
              />
              {this.state.searched ? (
                <div>
                  <div className="item-not-found-heading">
                    Seems like you want something that is unavailable in our
                    menu.
                  </div>
                  <div className="item-not-found-heading">
                    Meanwhile you can have a look in our catalogue.
                  </div>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => this.goToCatalogue()}
                  >
                    <div className="font-monst-bold">Catalogue</div>
                  </Button>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };
}

export default Search;

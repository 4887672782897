import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import {
  login,
  logout,
  addToCart,
  updateCartMetaData,
  resetCart,
  updateAdvDetails,
  updatePhonePe,
  updateTabValue,
  updateDeliveryDate,
  updateValiadtedCartResponse,
} from "../../Redux/actions";

import Authentication from "./Authentication";
import Search from "./Search";

import Slide from "@material-ui/core/Slide";
import { useScrollTrigger } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Toolbar from "@material-ui/core/Toolbar";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import RestaurantMenuRoundedIcon from "@material-ui/icons/RestaurantMenuRounded";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import PermPhoneMsgRoundedIcon from "@material-ui/icons/PermPhoneMsgRounded";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import * as Images from "../../Assets/Images";
import BaseUrl from "../BaseUrl";
import ApiSuffix from "../ApiSuffix";

import "./Header.css";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
    style: { background: "#ffb300" },
  });
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      openLoginDialog: false,
      openAdvDialog: false,
      activeValue: "/",
      openSessionDialog: false,
      openSearchDialog: false,
    };

    this.apiSuffix = ApiSuffix;
    this.baseUrl = BaseUrl;
  }

  componentDidMount() {
    if (navigator.userAgent.indexOf("phonepe-webview") === -1) {
      this.props.updatePhonePe(false);
    } else {
      this.props.updatePhonePe(true);
    }
    // this.setState({ activeValue: this.props.history.location.pathname });
    let products = JSON.parse(localStorage.getItem("products"));
    if (products && products.length > 0) {
      this.updateFrontEndCart(products);
    }
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.processLoginResponse(userInfo);
    } else {
      if (navigator.userAgent.indexOf("brijwasi-webview-android") !== -1) {
        this.toggleLoginDialog(true);
      }
    }
    this.getAdvDetails();
  }

  getAdvDetails = () => {
    const url = this.baseUrl.dev + this.apiSuffix.getSiteData;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        this.props.updateAdvDetails(data);
        if (
          data.showAnnouncement &&
          window.location.pathname.indexOf("/payment") === -1 &&
          window.location.pathname.indexOf("/reset-password") === -1
        ) {
          this.setState({ openSnack: true });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleCloseSnack = () => {
    this.setState({ openSnack: false });
  };

  processLoginResponse = (data) => {
    let authData = {
      authUser: {
        email: data.username,
        name: data.name,
      },
      authToken: "Token " + data.token,
    };
    this.props.login(authData.authToken, authData.authUser);
    this.handleLoginDialogClose();
    if (
      this.props.cartStore.products &&
      this.props.cartStore.products.length > 0
    ) {
      this.mergeCartItems(data.username, authData.authToken);
    } else {
      this.getCartInformation(data.username, authData.authToken);
    }
  };

  mergeCartItems = (email, token) => {
    let products = this.props.cartStore.products,
      productData = [];
    products.forEach((item) => {
      let miniItem = {
        productDisplayURL: item.product.productDisplayURL,
        quantity: item.quantity,
        variantID: item.selectedVariant.id,
      };
      productData.push(miniItem);
    });
    const url = this.baseUrl.dev + this.apiSuffix.mergeCartItems + email + "/";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          token && token.length > 0 ? token : this.props.authStore.authToken,
      },
      body: JSON.stringify(productData),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            this.setState({ openSessionDialog: true });
          } else {
            throw Error(response.statusText);
          }
        }
        return response.json();
      })
      .then((data) => {
        localStorage.removeItem("products");
        this.props.resetCart();
        this.getCartInformation(email, token);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getCartInformation = (email, token) => {
    const url = this.baseUrl.dev + this.apiSuffix.getCartItems + email + "/";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          token && token.length > 0 ? token : this.props.authStore.authToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            this.setState({ openSessionDialog: true });
          } else {
            throw Error(response.statusText);
          }
        }
        return response.json();
      })
      .then((data) => {
        if (data.products && data.products.length > 0) {
          this.updateFrontEndCart(data.products);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  updateFrontEndCart = (products) => {
    products.forEach((item) => {
      this.props.addToCart(item);
    });
    this.props.updateCartMetaData();
    if (this.props.history.location.pathname === "/cart") {
      this.validateCart();
    }
  };

  validateCart = () => {
    let cartInstance = [];
    this.props.cartStore.products.forEach((item) => {
      let inst = {
        productDisplayURL: item.product.productDisplayURL,
        quantity: item.quantity,
      };
      cartInstance.push(inst);
    });
    let date = this.props.cartStore.deliveryDate
      ? this.props.cartStore.deliveryDate
      : new Date();
    let diff = new Date().getTimezoneOffset() * 60000;
    let currentdt = new Date(date.getTime() - diff).toISOString();
    let info = {
      cartInstance: cartInstance,
      date: currentdt,
      deliveryType: this.props.cartStore.deliveryType,
      deliveryPincode: localStorage.getItem("pincode"),
    };
    const url = this.baseUrl.dev + this.apiSuffix.validateCart;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(info),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        this.props.updateValiadtedCartResponse(data);
        this.props.updateDeliveryDate(new Date());
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  navigateTo = (route) => {
    if (this.props.history.location.pathname !== route) {
      this.props.history.push(route);
    }
  };

  toggleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  logout = () => {
    let token = this.props.authStore.authToken;
    this.handleMenuClose();
    localStorage.removeItem("userInfo");
    this.setState({ anchorEl: null });
    this.props.logout();
    this.props.resetCart();
    this.props.history.push("/");
    const url = this.baseUrl.dev + this.apiSuffix.logout;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {})
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ loading: false, fetchError: true });
      });
  };

  toggleLoginDialog = (toggle) => {
    this.setState({ openLoginDialog: toggle });
  };

  handleLoginDialogClose = () => {
    this.setState({ openLoginDialog: false });
  };

  handleAdvDialogClose = () => {
    this.setState({ openAdvDialog: false });
  };

  handleSessionClose = () => {
    this.setState({ openSessionDialog: false });
    localStorage.removeItem("userInfo");
    this.props.logout();
    this.props.resetCart();
    this.props.history.push("/");
  };

  goToProfile = () => {
    this.handleMenuClose();
    this.props.history.push("/profile");
  };

  handleChange = (e, newValue) => {
    if (this.props.history.location.pathname !== newValue) {
      this.props.history.push(newValue);
      this.props.updateTabValue(newValue);
    }
  };

  toggleSearchPopup = () => {
    let newVal = this.state.openSearchDialog ? false : true;
    this.setState({ openSearchDialog: newVal });
  };

  handleSearchDialogClose = () => {
    this.setState({ openSearchDialog: false });
  };

  openWhatsapp = () => {
    let url =
      "https://api.whatsapp.com/send?phone=+918808011133&text=Hi Brijwasi Bakery,%0D%0AI would like to know more about your products.";
    window.open(url, "_blank");
  };

  render = () => {
    return (
      <React.Fragment>
        <div>
          <ElevationScroll {...this.props}>
            <AppBar position="fixed">
              <Toolbar>
                <div className="title">
                  <Link to="/">
                    <img
                      src={Images.INVOICE_LOGO}
                      alt="Brijwasi Logo"
                      style={{ width: "135px" }}
                    />
                  </Link>
                </div>
                <div className="long-menu">
                  {/* <Button onClick={(e) => this.navigateTo("/")}>
                    <div className="header-btn">Home</div>
                  </Button> */}
                  <Button
                    className="btn-fade"
                    onClick={(e) => this.navigateTo("/catalogue")}
                  >
                    {/* <Link to="/catalogue" className="header-btn"> */}
                    <span className="header-btn">Catalogue</span>
                    {/* </Link> */}
                  </Button>
                  {/* <Button onClick={(e) => this.navigateTo("/")}>
                    <div className="header-btn">Blog</div>
                  </Button> */}
                  <Button
                    className="btn-fade"
                    onClick={(e) => this.navigateTo("/about-us")}
                  >
                    {/* <Link to="/about-us" className="header-btn"> */}
                    <span className="header-btn">About Us</span>
                    {/* </Link> */}
                  </Button>
                  <Button
                    className="btn-fade"
                    onClick={(e) => this.navigateTo("/contact-us")}
                  >
                    {/* <Link to="/contact-us" className="header-btn"> */}
                    <span className="header-btn">Contact Us</span>
                    {/* </Link> */}
                  </Button>
                  <Button
                    className="header-btn"
                    onClick={(e) => this.navigateTo("/cart")}
                  >
                    {/* <Link to="/cart" className="header-btn"> */}
                    <Badge
                      color="secondary"
                      variant="dot"
                      invisible={this.props.cartStore.products.length === 0}
                    >
                      <LocalMallIcon />
                    </Badge>
                    <span className="header-btn">Cart</span>
                    {/* </Link> */}
                  </Button>
                  {this.props.authStore.isAuthenticated ? (
                    <React.Fragment>
                      <IconButton
                        className="btn-fade"
                        aria-controls="profile-menu"
                        aria-haspopup="true"
                        onClick={(e) => this.toggleMenu(e)}
                      >
                        <Avatar className="header-avatar">
                          {this.props.authStore.userProfile.name.charAt(0)}
                        </Avatar>
                      </IconButton>
                    </React.Fragment>
                  ) : (
                    <Button
                      className="btn-fade"
                      onClick={(e) => this.toggleLoginDialog(true)}
                    >
                      <AccountCircleRoundedIcon />
                      <div className="header-btn">Account</div>
                    </Button>
                  )}
                  <Menu
                    id="profile-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleMenuClose}
                  >
                    <MenuItem onClick={this.goToProfile}>
                      <span className="font-monst-bold">My Account</span>
                    </MenuItem>
                    <MenuItem onClick={this.logout}>
                      <span className="font-monst-bold">Logout</span>
                    </MenuItem>
                  </Menu>
                </div>
                <div className="drawer">
                  {this.props.authStore.isAuthenticated ? (
                    <IconButton
                      aria-controls="profile-menu"
                      aria-haspopup="true"
                      onClick={(e) => this.toggleMenu(e)}
                    >
                      <Avatar className="footer-avatar">
                        {this.props.authStore.userProfile.name.charAt(0)}
                      </Avatar>
                    </IconButton>
                  ) : (
                    <IconButton onClick={(e) => this.toggleLoginDialog(true)}>
                      <AccountCircleRoundedIcon />
                    </IconButton>
                  )}
                </div>
              </Toolbar>
            </AppBar>
          </ElevationScroll>
          {!this.props.authStore.isPhonePe ? (
            navigator.userAgent.indexOf("brijwasi-webview") !== -1 ? null : (
              <button className="contact-fab-btn" onClick={this.openWhatsapp}>
                <WhatsAppIcon style={{ width: "1.4em", height: "1.4em" }} />
              </button>
            )
          ) : null}
          <button className="search-fab-btn" onClick={this.toggleSearchPopup}>
            <SearchRoundedIcon />
          </button>
        </div>
        <Dialog
          open={this.state.openLoginDialog}
          onClose={this.handleLoginDialogClose}
          aria-labelledby="form-dialog-title"
          className="lrd-dialog"
          disableBackdropClick={
            navigator.userAgent.indexOf("brijwasi-webview-android") !== -1
              ? true
              : false
          }
          disableEscapeKeyDown={
            navigator.userAgent.indexOf("brijwasi-webview-android") !== -1
              ? true
              : false
          }
        >
          <DialogContent>
            <div>
              <Authentication
                processLoginResponse={this.processLoginResponse}
              />
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.openSearchDialog}
          onClose={this.handleSearchDialogClose}
          aria-labelledby="form-dialog-title"
          className="lrd-dialog"
          fullScreen
          TransitionComponent={Transition}
        >
          <DialogContent>
            <Search
              closeDialog={this.handleSearchDialogClose}
              history={this.props.history}
            />
          </DialogContent>
        </Dialog>
        <Snackbar
          open={this.state.openSnack}
          autoHideDuration={4000}
          onClose={this.handleCloseSnack}
        >
          <Alert onClose={this.handleCloseSnack} severity="error">
            {this.props.authStore.advDetails &&
            this.props.authStore.advDetails.announcements &&
            this.props.authStore.advDetails.announcements.length > 0
              ? this.props.authStore.advDetails.announcements.map(
                  (item, index) => <div key={index}>{item}</div>
                )
              : null}
          </Alert>
        </Snackbar>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.openSessionDialog}
          onClose={this.handleSessionClose}
          aria-labelledby="form-dialog-title"
          className="lrd-dialog"
          TransitionComponent={Transition}
        >
          <DialogContent>
            <div style={{ margin: "20px", textAlign: "center" }}>
              <h3>Session Expired</h3>
              <div>
                <img
                  src={Images.CACTUS_CRY}
                  className="session-expiry-image"
                  alt="session expired"
                />
                <div className="session-expiry-heading">
                  Your Session is expired. Please Login again to continue.
                </div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.handleSessionClose}
                >
                  <span className="font-monst-bold">Close</span>
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <div className="bottom-navigation">
          <BottomNavigation
            value={this.props.authStore.activeTabValue}
            onChange={this.handleChange}
            style={{ background: "#ffb300" }}
            showLabels
          >
            <BottomNavigationAction
              value="/"
              icon={<HomeRoundedIcon />}
              label="Home"
            />
            <BottomNavigationAction
              value="/catalogue"
              icon={<RestaurantMenuRoundedIcon />}
              label="Menu"
            />
            <BottomNavigationAction
              value="/cart"
              icon={
                <Badge
                  color="secondary"
                  variant="dot"
                  invisible={this.props.cartStore.products.length === 0}
                >
                  <LocalMallIcon />
                </Badge>
              }
              label="Cart"
            />
            <BottomNavigationAction
              value="/about-us"
              icon={<InfoRoundedIcon />}
              label="About"
            />
            <BottomNavigationAction
              value="/contact-us"
              icon={<PermPhoneMsgRoundedIcon />}
              label="Contact"
            />
            <BottomNavigationAction
              value="/blog"
              icon={<MenuBookRoundedIcon />}
              label="Blog"
            />
          </BottomNavigation>
        </div>
      </React.Fragment>
    );
  };
}

const mapStateToProps = (state) => ({
  cartStore: state.cartStore,
  authStore: state.authStore,
});

const mapDispatchToProps = (dispatch) => ({
  login: (token, profile) => dispatch(login(token, profile)),
  logout: () => dispatch(logout()),
  addToCart: (product) => dispatch(addToCart(product)),
  updateCartMetaData: () => dispatch(updateCartMetaData()),
  resetCart: () => dispatch(resetCart()),
  updateAdvDetails: (details) => dispatch(updateAdvDetails(details)),
  updatePhonePe: (status) => dispatch(updatePhonePe(status)),
  updateTabValue: (tabValue) => dispatch(updateTabValue(tabValue)),
  updateDeliveryDate: (delDate) => dispatch(updateDeliveryDate(delDate)),
  updateValiadtedCartResponse: (cartResponse) =>
    dispatch(updateValiadtedCartResponse(cartResponse)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);

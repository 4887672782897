import React, { Suspense, lazy } from "react";

import { Provider } from "react-redux";
import store from "./Redux/store";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import Header from "./Util/Header/Header";
import Loader from "./Util/Loader/Loader";

import "./App.css";

const Dashboard = lazy(() =>
  import(/* webpackChunkName: "Dashboard" */ "./Dashboard/Dashboard")
);

const Catalogue = lazy(() =>
  import(/* webpackChunkName: "Catalogue" */ "./Catalogue/Catalogue")
);

const Category = lazy(() =>
  import(/* webpackChunkName: "Category" */ "./Category/Category")
);

const Product = lazy(() =>
  import(/* webpackChunkName: "Product" */ "./Product/Product")
);

const Cart = lazy(() => import(/* webpackChunkName: "Cart" */ "./Cart/Cart"));

const Profile = lazy(() =>
  import(/* webpackChunkName: "Profile" */ "./Profile/Profile")
);

const Checkout = lazy(() =>
  import(/* webpackChunkName: "Checkout" */ "./Checkout/Checkout")
);

const ContactUs = lazy(() =>
  import(/* webpackChunkName: "Contact" */ "./ContactUs/ContactUs")
);

const Stores = lazy(() =>
  import(/* webpackChunkName: "Stores" */ "./Stores/Stores")
);

const StoresMenu = lazy(() =>
  import(/* webpackChunkName: "StoresMenu" */ "./StoresMenu/StoresMenu")
);

const PaymentInitiate = lazy(() =>
  import(/* webpackChunkName: "Payment" */ "./Checkout/PaymentInitiate")
);

const OrderDetail = lazy(() =>
  import(/* webpackChunkName: "OrderDetail" */ "./OrderDetail/OrderDetail")
);

const ResetPassword = lazy(() =>
  import(
    /* webpackChunkName: "ResetPassword" */ "./ResetPassword/ResetPassword"
  )
);

const Pages = lazy(() =>
  import(/* webpackChunkName: "Pages" */ "./Pages/Pages")
);

const AboutUs = lazy(() =>
  import(/* webpackChunkName: "About" */ "./AboutUs/AboutUs")
);

const PaymentSuccess = lazy(() =>
  import(
    /* webpackChunkName: "PaymentSuccess" */ "./PaymentUrls/PaymentSuccess"
  )
);

const PaymentFailed = lazy(() =>
  import(/* webpackChunkName: "PaymentFailed" */ "./PaymentUrls/PaymentFailed")
);

const PaymentCancelled = lazy(() =>
  import(
    /* webpackChunkName: "PaymentCancelled" */ "./PaymentUrls/PaymentCancelled"
  )
);

const Blog = lazy(() => import(/* webpackChunkName: "Blog" */ "./Blog/Blog"));

const BlogContent = lazy(() =>
  import(/* webpackChunkName: "BlogContent" */ "./Blog/BlogContent")
);

const CustomPayment = lazy(() =>
  import(
    /* webpackChunkName: "CustomPayment" */ "./CustomPayment/CustomPayment"
  )
);

const CustomPaymentInitiate = lazy(() =>
  import(
    /* webpackChunkName: "CustomPaymentInitiate" */ "./CustomPayment/CustomPaymentIntiate"
  )
);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffb300",
    },
    secondary: {
      main: "#121212",
    },
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.customHistory = createBrowserHistory();
  }

  render = () => {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router history={this.customHistory}>
            <Suspense
              fallback={
                <div className="loader-container">
                  <Loader />
                </div>
              }
            >
              <Header history={this.customHistory} />
              <Switch>
                <Route exact path="/" component={Dashboard}></Route>
                <Route path="/catalogue" component={Catalogue}></Route>
                <Route path="/category/:name" component={Category}></Route>
                <Route path="/stores/:category" component={Stores} />
                <Route path="/store-menu/:storeId" component={StoresMenu} />
                <Route path="/shop/:name/:prodName" component={Product}></Route>
                <Route path="/cart" component={Cart}></Route>
                <Route path="/profile" component={Profile}></Route>
                <Route path="/checkout" component={Checkout}></Route>
                <Route path="/contact-us" component={ContactUs}></Route>
                <Route path="/payment" component={PaymentInitiate}></Route>
                <Route path="/order/:orderId" component={OrderDetail}></Route>
                <Route path="/pages/:pageType" component={Pages}></Route>
                <Route path="/about-us" component={AboutUs}></Route>
                <Route path="/blog" component={Blog} />
                <Route path="/blog-content/:id" component={BlogContent} />
                <Route path="/custom-order" component={CustomPayment} />
                <Route
                  path="/custom-payment"
                  component={CustomPaymentInitiate}
                />
                <Route
                  path="/payment-success/:orderId"
                  component={PaymentSuccess}
                />
                <Route path="/payment-failed" component={PaymentFailed} />
                <Route path="/payment-cancelled" component={PaymentCancelled} />
                <Route
                  path="/reset-password/:uid/:token"
                  component={ResetPassword}
                />
                <Route component={Catalogue} />
              </Switch>
            </Suspense>
          </Router>
        </ThemeProvider>
      </Provider>
    );
  };
}

export default App;

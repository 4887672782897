import React from "react";

import "./ImageLoader.css";

class ImageLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgeloaded: false,
      url: "/sq-min.jpg",
    };
  }

  imageLoaded = () => {
    this.setState({ imgLoaded: true });
  };

  render = () => {
    return (
      <div>
        <div
          className={
            this.state.imgLoaded ? "image-loader-zero-state" : "base-loader"
          }
        >
          <img
            src="/sq-min.jpg"
            className={
              this.state.imgLoaded ? "image-loader-zero-state" : "loader-image"
            }
            alt="brijwasi-bakery"
          />
        </div>
        <img
          className={
            this.state.imgLoaded
              ? "image-loader-loaded"
              : "image-loader-zero-state"
          }
          onLoad={(e) => this.imageLoaded()}
          alt={this.props.alt}
          src={this.props.minUrl ? this.props.minUrl : this.props.src}
        />
      </div>
    );
  };
}

export default ImageLoader;

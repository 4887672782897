import React from "react";

import * as IMAGES from "../../Assets/Images";
import BaseUrl from "../BaseUrl";
import ApiSuffix from "../ApiSuffix";

import {
  Button,
  TextField,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import ReactGA from "react-ga";

import "./Header.css";

class Authentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      userEmail: "",
      userMobNo: "",
      password: "",
      showPassword: false,
      logging: false,
      loginError: false,
      tabValue: 0,
      userNameError: true,
      userEmailError: false,
      userMobNoError: false,
      passwordError: false,
      registering: false,
      registerError: false,
      registerErrorMessage: "",
      resetMode: false,
      resetEmail: "",
      resetEmailError: false,
      resetting: false,
      resetLinkSent: false,
      otpLogin: true,
      sendingOtp: false,
      otpLevel: 0,
      otpMobileNo: "",
      verifyingOtp: false,
      otpErrorMessage: "",
      subscribe: false,
    };
    this.baseUrl = BaseUrl;
    this.apiSuffix = ApiSuffix;
  }

  handleUserNameChange = (ev) => {
    this.setState({ userName: ev.target.value });
  };

  handleUserEmailChange = (ev) => {
    this.setState({ userEmail: ev.target.value.toLowerCase() });
  };

  changeMobileNo = (e) => {
    if (e.target.value.length <= 10) {
      this.setState({ userMobNo: e.target.value });
    }
  };

  handleMobNoChange = (e) => {
    if (e.target.value.length <= 10) {
      this.setState({ otpMobileNo: e.target.value });
    }
  };

  generateOTP = () => {
    if (this.state.otpMobileNo.length === 10) {
      this.setState({ sendingOtp: true });
      let url =
        this.baseUrl.dev + this.apiSuffix.genOtp + this.state.otpMobileNo + "/";
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          this.setState({ sendingOtp: false });
          if (data.status === 1) {
            this.setState({ otpLevel: 1, otpErrorMessage: "" });
          } else {
            this.setState({ otpLevel: 0, otpErrorMessage: data.message });
          }
        })
        .catch((error) => {
          this.setState({ sendingOtp: false });
          console.error("Error:", error);
        });
    }
  };

  verifyOtp = () => {
    this.setState({ verifyingOtp: true });
    let otpData = {
      mobileNo: this.state.otpMobileNo,
      otp: Number(this.state.otpNo),
    };
    let url = this.baseUrl.dev + this.apiSuffix.verifyOtp;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(otpData),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ verifyingOtp: false });
        if (data.status === 1) {
          localStorage.setItem("userInfo", JSON.stringify(data));
          ReactGA.initialize("UA-162083606-1");
          ReactGA.event({
            category: "User",
            action: "User-Login",
          });
          this.props.processLoginResponse(data);
        } else {
          this.setState({ otpErrorMessage: data.message });
        }
      })
      .catch((error) => {
        this.setState({ verifyingOtp: false, otpErrorMessage: "" });
        console.error("Error:", error);
      });
  };

  handleOtpChange = (e) => {
    this.setState({ otpNo: e.target.value });
  };

  handleResetEmailChange = (ev) => {
    this.setState({ resetEmail: ev.target.value.toLowerCase() });
  };

  handlePasswordChange = (ev) => {
    this.setState({ password: ev.target.value });
  };

  handleClickShowPassword = () => {
    let val = this.state.showPassword;
    this.setState({ showPassword: !val });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  setResetMode = (mode) => {
    this.setState({
      resetEmail: "",
      resetEmailError: false,
      resetting: false,
      resetLinkSent: false,
    });
    this.setState({ resetMode: mode });
    this.resetStates();
  };

  validateLogin = () => {
    this.setState({
      loginError: false,
      userEmailError: false,
      passwordError: false,
    });
    if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        this.state.userEmail
      ) &&
      this.state.password.length >= 8
    ) {
      this.setState({
        userEmailError: false,
        passwordError: false,
      });
      this.login();
    } else {
      if (
        !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
          this.state.userEmail
        )
      ) {
        this.setState({ userEmailError: true });
      }
      if (this.state.password.length < 8) {
        this.setState({ passwordError: true });
      }
    }
  };

  login = () => {
    this.setState({ logging: true });
    let loginData = {
      username: this.state.userEmail,
      password: this.state.password,
    };
    let url = this.baseUrl.dev + this.apiSuffix.login;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem("userInfo", JSON.stringify(data));
        ReactGA.initialize("UA-162083606-1");
        ReactGA.event({
          category: "User",
          action: "User-Login",
        });
        this.props.processLoginResponse(data);
      })
      .catch((error) => {
        this.setState({ logging: false, loginError: true });
        console.error("Error:", error);
      });
  };

  handleTabChange = (newValue) => {
    this.setState({
      tabValue: newValue,
    });
    this.resetStates();
  };

  resetStates = () => {
    this.setState({
      userName: "",
      userEmail: "",
      password: "",
      userMobNo: "",
      userNameError: false,
      userEmailError: false,
      userMobNoError: false,
      passwordError: false,
      loginError: false,
      registerError: false,
      resetError: false,
      registerErrorMessage: "",
      resetErrorMessage: "",
      subscribe: false,
    });
  };

  validateRegister = () => {
    this.setState({
      userNameError: false,
      userEmailError: false,
      passwordError: false,
      registerError: false,
      userMobNoError: false,
      registerErrorMessage: "",
    });
    if (
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        this.state.userEmail
      ) &&
      this.state.password.length >= 8 &&
      this.state.userName.length > 0 &&
      this.state.userMobNo.toString().length === 10
    ) {
      this.register();
    } else {
      if (
        !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
          this.state.userEmail
        )
      ) {
        this.setState({ userEmailError: true });
      }
      if (this.state.password.length < 8) {
        this.setState({ passwordError: true });
      }
      if (this.state.userName.length === 0) {
        this.setState({ userNameError: true });
      }
      if (this.state.userMobNo.toString().length !== 10) {
        this.setState({ userMobNoError: true });
      }
    }
  };

  register = () => {
    this.setState({ registering: true });
    let registerData = {
      username: this.state.userEmail,
      password: this.state.password,
      first_name: this.state.userName,
      mobileNo: this.state.userMobNo,
    };
    let url = this.baseUrl.dev + this.apiSuffix.register;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(registerData),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((err) => {
            throw Error(err.message);
          });
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ registering: false });
        localStorage.setItem("userInfo", JSON.stringify(data));
        ReactGA.initialize("UA-162083606-1");
        ReactGA.event({
          category: "User",
          action: "User-Register",
        });
        this.props.processLoginResponse(data);
      })
      .catch((error) => {
        this.setState({
          registering: false,
          registerError: true,
          registerErrorMessage: error.message,
        });
        console.error("Error:", error.message);
      });
  };

  resetEmailSubmit = () => {
    this.setState({ resetEmailError: false });
    if (
      !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
        this.state.resetEmail
      )
    ) {
      this.setState({ resetEmailError: true });
    } else {
      this.setState({ resetting: true });
      let resetData = {
        email: this.state.resetEmail,
        baseUrl: window.location.origin,
      };
      let url = this.baseUrl.dev + this.apiSuffix.sendResetPasswordMail;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(resetData),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((err) => {
              throw Error(err.message);
            });
          }
          return response.json();
        })
        .then((data) => {
          ReactGA.initialize("UA-162083606-1");
          ReactGA.event({
            category: "User",
            action: "Reset Email Trigger",
          });
          this.setState({ resetting: false, resetLinkSent: true });
        })
        .catch((error) => {
          this.setState({
            resetting: false,
            resetLinkSent: false,
            resetError: true,
            resetErrorMessage: error.message,
          });
          console.error("Error:", error.message);
        });
    }
  };

  setOTPLogin = (value) => {
    this.setState({ otpLogin: value });
    this.resetOTP();
  };

  resetOTP = () => {
    this.setState({ otpLevel: 0, otpMobileNo: "", otpErrorMessage: "" });
  };

  handleSubscribeChange = (e) => {
    this.setState({ subscribe: e.target.checked });
  };
  render = () => {
    return (
      <React.Fragment>
        <div className="lrd-dialog-wrapper">
          <div className="lrd-left-panel">
            <img
              src={IMAGES.LOGIN_IMAGE}
              alt="Muffins"
              style={{ height: "540px", display: "block" }}
            />
          </div>
          {this.state.resetMode ? (
            <div className="lrd-right-panel">
              <div className="lrd-heading">
                Welcome to the land of Cakes & Cookies.
              </div>
              <div className="lrd-subheading">
                Its completely human to forget things.
              </div>
              <div className="lrd-subheading">
                Enter your registered Email and we will send you the password
                recovery link.
              </div>
              {this.state.resetLinkSent ? (
                <div className="resentLinkTick">
                  <img
                    src={IMAGES.TICK}
                    alt="done"
                    style={{ width: "100px" }}
                  />
                  <div style={{ margin: "10px 0px", fontWeight: "bold" }}>
                    Your Password recovery email is on the way.
                  </div>
                </div>
              ) : (
                <div className="lrd-login-form">
                  <TextField
                    id="user-email"
                    type="text"
                    label="Recovery Email"
                    fullWidth
                    className="text-field-margin"
                    // margin="dense"
                    variant="outlined"
                    value={this.state.resetEmail}
                    placeholder="Please enter your email."
                    onChange={this.handleResetEmailChange}
                    error={this.state.resetEmailError}
                  />
                  {this.state.resetError ? (
                    <div className="login-error">
                      {this.state.resetErrorMessage}
                    </div>
                  ) : null}
                  <div style={{ margin: "15px 0px" }}>
                    {this.state.resetting ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        // className="btn-fade"
                        color="primary"
                        variant="contained"
                        onClick={(e) => this.resetEmailSubmit()}
                      >
                        <div className="font-monst-bold">Submit</div>
                      </Button>
                    )}
                  </div>
                </div>
              )}
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => this.setResetMode(false)}
                >
                  <div className="font-monst-bold">Back to Login</div>
                </Button>
              </div>
            </div>
          ) : (
            <div className="lrd-right-panel">
              <div className="lrd-heading">
                Welcome to the land of Cakes & Cookies.
              </div>
              <div className="lrd-subheading">
                Log In or Register to continue.
              </div>
              <div className="lrd-tabs-wrapper">
                <div
                  onClick={(e) => this.handleTabChange(0)}
                  className={
                    this.state.tabValue === 0 ? "lrd-active-tab" : "lrd-tab"
                  }
                >
                  Login
                </div>
                <div
                  onClick={(e) => this.handleTabChange(1)}
                  className={
                    this.state.tabValue === 1 ? "lrd-active-tab" : "lrd-tab"
                  }
                >
                  Register
                </div>
              </div>
              {this.state.tabValue === 0 ? (
                this.state.otpLogin ? (
                  <div className="lrd-login-form">
                    <TextField
                      id="user-mobile"
                      type="number"
                      label="Mobile"
                      fullWidth
                      className="text-field-margin"
                      variant="outlined"
                      disabled={this.state.otpLevel === 1}
                      value={this.state.otpMobileNo}
                      placeholder="Enter your mobile number"
                      onChange={this.handleMobNoChange}
                      error={this.state.mobNoError}
                    />
                    {this.state.otpLevel === 1 ? (
                      <div>
                        <TextField
                          id="otp"
                          type="number"
                          label="OTP"
                          fullWidth
                          className="text-field-margin"
                          variant="outlined"
                          value={this.state.otpNo}
                          placeholder="Enter your OTP"
                          onChange={this.handleOtpChange}
                        />
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={this.resetOTP}
                          style={{ margin: " 0px 10px " }}
                        >
                          <div className="font-monst-bold">Reset</div>
                        </Button>
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={this.generateOTP}
                          style={{ margin: "5px 0px" }}
                        >
                          <div className="font-monst-bold">Resend OTP</div>
                        </Button>
                      </div>
                    ) : null}
                    {this.state.otpErrorMessage.length > 0 ? (
                      <div className="login-error">
                        {this.state.otpErrorMessage}
                      </div>
                    ) : null}
                    {this.state.otpLevel === 0 ? (
                      this.state.sendingOtp ? (
                        <div>
                          <CircularProgress />
                        </div>
                      ) : (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={(e) => this.generateOTP()}
                        >
                          <div className="font-monst-bold">Generate OTP</div>
                        </Button>
                      )
                    ) : this.state.verifyingOtp ? (
                      <div>
                        <CircularProgress />
                      </div>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={(e) => this.verifyOtp()}
                      >
                        <div className="font-monst-bold">Verify OTP</div>
                      </Button>
                    )}
                    <Button
                      className="mobile-otp-btn"
                      onClick={(e) => this.setOTPLogin(false)}
                    >
                      <div className="font-monst-bold">Login with Email</div>
                    </Button>
                  </div>
                ) : (
                  <div className="lrd-login-form">
                    <form>
                      <TextField
                        id="user-email"
                        type="text"
                        label="Email"
                        fullWidth
                        className="text-field-margin"
                        // margin="dense"
                        variant="outlined"
                        value={this.state.userEmail}
                        placeholder="Please enter your email."
                        onChange={this.handleUserEmailChange}
                        error={this.state.userEmailError}
                      />
                      <OutlinedInput
                        id="password"
                        type={this.state.showPassword ? "text" : "password"}
                        // size="small"
                        // margin="dense"
                        fullWidth
                        className="text-field-margin"
                        value={this.state.password}
                        placeholder="Enter the password"
                        onChange={this.handlePasswordChange}
                        error={this.state.passwordError}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}
                            >
                              {this.state.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {this.state.userEmailError || this.state.passwordError ? (
                        <div className="login-error">
                          {this.state.userEmailError &&
                          !this.state.passwordError
                            ? "Please enter a valid email address."
                            : !this.state.userEmailError &&
                              this.state.passwordError
                            ? "Password length should be greater than 7"
                            : this.state.userEmailError &&
                              this.state.passwordError
                            ? "Invalid email and Password is too short"
                            : null}
                        </div>
                      ) : null}
                      {this.state.loginError ? (
                        <div className="login-error">
                          Wrong Email Id or Password.
                        </div>
                      ) : null}
                      {this.state.logging ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          color="primary"
                          variant="contained"
                          // className="btn-fade"
                          onClick={(e) => this.validateLogin()}
                        >
                          <div className="font-monst-bold">Login</div>
                        </Button>
                      )}
                    </form>
                    <Button
                      style={{ margin: "5px 0px" }}
                      onClick={(e) => this.setOTPLogin(true)}
                    >
                      <div className="font-monst-bold">
                        Login with Mobile OTP
                      </div>
                    </Button>
                    <Button
                      color="secondary"
                      variant="outlined"
                      // className="btn-fade"
                      onClick={(e) => this.setResetMode(true)}
                    >
                      <div className="font-monst-bold">
                        Forgot your password?
                      </div>
                    </Button>
                  </div>
                )
              ) : (
                <div className="lrd-register-form">
                  <form>
                    <TextField
                      id="userName"
                      type="text"
                      label="Name"
                      fullWidth
                      style={{ margin: "10px 0px" }}
                      margin="dense"
                      variant="outlined"
                      value={this.state.userName}
                      placeholder="Please enter your Name"
                      onChange={this.handleUserNameChange}
                      error={this.state.userNameError}
                    />
                    <TextField
                      id="user-email"
                      type="text"
                      label="Email"
                      fullWidth
                      className="text-field-margin"
                      margin="dense"
                      variant="outlined"
                      value={this.state.userEmail}
                      placeholder="Please enter your email."
                      onChange={this.handleUserEmailChange}
                      error={this.state.userEmailError}
                    />
                    <TextField
                      id="user-mobile"
                      type="number"
                      placeholder="Enter your mobile number"
                      label="Mobile Number"
                      fullWidth
                      style={{ marginTop: "10px" }}
                      value={this.state.userMobNo}
                      variant="outlined"
                      margin="dense"
                      className="text-field-margin"
                      onChange={(e) => this.changeMobileNo(e)}
                      error={this.state.userMobNoError}
                    />
                    <OutlinedInput
                      id="password"
                      type={this.state.showPassword ? "text" : "password"}
                      fullWidth
                      margin="dense"
                      className="text-field-margin"
                      value={this.state.password}
                      placeholder="Enter the password"
                      onChange={this.handlePasswordChange}
                      error={this.state.passwordError}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                          >
                            {this.state.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <FormControlLabel
                      style={{ margin: "10px 0px" }}
                      control={
                        <Checkbox
                          checked={this.state.subscribe}
                          onChange={(e) => this.handleSubscribeChange(e)}
                          name="subscribe"
                        />
                      }
                      label={
                        <span style={{ fontSize: "0.85rem" }}>
                          I agree to Brijwasi's{" "}
                          <a href="https://www.brijwasibakery.com/pages/terms-and-conditions">
                            Term's and Conditions
                          </a>{" "}
                          &{" "}
                          <a href="https://www.brijwasibakery.com/pages/privacy-policy">
                            Privacy Policy
                          </a>
                        </span>
                      }
                    />
                    {this.state.userEmailError ||
                    this.state.passwordError ||
                    this.state.userNameError ||
                    this.state.userMobNoError ? (
                      <div className="login-error">
                        {this.state.userEmailError &&
                        !this.state.passwordError &&
                        !this.state.userNameError
                          ? "Please enter a valid email address."
                          : !this.state.userEmailError &&
                            this.state.passwordError &&
                            !this.state.userNameError
                          ? "Password length should be greater than 7"
                          : this.state.userEmailError &&
                            this.state.passwordError &&
                            !this.state.userNameError
                          ? "Invalid email and Password is too short"
                          : this.state.userEmailError &&
                            !this.state.passwordError &&
                            this.state.userNameError
                          ? "Invalid Name and Invalid email address."
                          : !this.state.userEmailError &&
                            this.state.passwordError &&
                            this.state.userNameError
                          ? "Invalid name & Password length should be greater than 7"
                          : this.state.userEmailError &&
                            this.state.passwordError &&
                            this.state.userNameError
                          ? "Invalid name, email and Password is too short"
                          : this.state.userNameError &&
                            !this.state.userEmailError &&
                            !this.state.passwordError
                          ? "Invalid Name"
                          : this.state.userMobNoError
                          ? "Invalid Mobile Number"
                          : null}
                      </div>
                    ) : null}
                    {this.state.registerError ? (
                      <div className="login-error">
                        {this.state.registerErrorMessage}
                      </div>
                    ) : null}
                    {this.state.registering ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        // className="btn-fade"
                        color="primary"
                        variant="contained"
                        disabled={!this.state.subscribe}
                        onClick={(e) => this.validateRegister()}
                      >
                        <div className="font-monst-bold">Register</div>
                      </Button>
                    )}
                  </form>
                </div>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };
}

export default Authentication;
